/**
 * @generated SignedSource<<4f07840cf6affc19bdea1158c15593ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type blockquoteQuoteContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"contentShareWidgetContent">;
  readonly " $fragmentType": "blockquoteQuoteContent";
};
export type blockquoteQuoteContent$key = {
  readonly " $data"?: blockquoteQuoteContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"blockquoteQuoteContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blockquoteQuoteContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentShareWidgetContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "f90b6ded129ebec779315142b1c3ea0e";

export default node;
