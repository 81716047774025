import styled from "@emotion/styled";

import { Loading } from "scmp-app/components/common/loading";

export const Container = styled.div`
  margin-block: 20px;
`;

export const StyledLoading = styled(Loading)`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;

  transform: translate(-50%, -50%);
`;
