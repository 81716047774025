import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import PhotoGalleryIcon from "./icon-photo-gallery.svg";

export const Indicator = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-self: start end;
  justify-content: center;
  align-items: center;

  inline-size: 60px;
  block-size: 60px;
  margin: 16px;

  border-radius: 50%;

  background: #141518;

  cursor: pointer;

  opacity: 0.9;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 80px;
    block-size: 80px;
    margin: 24px;
  }
`;

export const IndicatorIcon = styled(PhotoGalleryIcon)`
  inline-size: 16px;
  block-size: 16px;
  ${theme.breakpoints.up("tablet")} {
    inline-size: 20px;
    block-size: 20px;
  }
`;

export const IndicatorValue = styled.div`
  margin-block-start: 6px;

  color: #ffffff;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 14px;
  ${theme.breakpoints.up("tablet")} {
    font-size: 18px;
    line-height: 18px;
  }
`;
