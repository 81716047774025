import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { graphql, useQueryLoader } from "react-relay";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { PhotoGalleryWidget } from "scmp-app/components/schema-render/common/div/photo-gallery/photo-gallery-widget";
import {
  BottomCell,
  ImageGrid,
  MainCell,
} from "scmp-app/components/schema-render/common/div/photo-gallery/photo-gallery-widget/styles";
import type {
  ContentSchemaRenderProps,
  RenderComponent,
} from "scmp-app/components/schema-render/content";
import type { contentSchemaRenderContent$data } from "scmp-app/queries/__generated__/contentSchemaRenderContent.graphql";
import type { photoGalleryQuery } from "scmp-app/queries/__generated__/photoGalleryQuery.graphql";

import { Container, StyledLoading } from "./styles";

export const query = graphql`
  query photoGalleryQuery($entityId: String!) {
    content(contentType: GALLERY, filter: { entityId: $entityId }) {
      ...photoGalleryWidgetGallery
    }
  }
`;

type Props = ContentSchemaRenderProps & {
  className?: string;
  reference?: contentSchemaRenderContent$data;
};

const Component: FunctionComponent<Props> = ({ className, reference, schemaNode }) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<photoGalleryQuery>(query);
  const galleryEntityId = schemaNode.attribs?.["data-gallery-nid"];

  useEffect(() => {
    if (!galleryEntityId) return;
    loadQuery({ entityId: galleryEntityId });

    return disposeQuery;
  }, [disposeQuery, galleryEntityId, loadQuery]);

  if (!galleryEntityId || !reference) return null;

  const renderFallback = () => (
    <ImageGrid>
      <MainCell />
      <BottomCell />
      <StyledLoading />
    </ImageGrid>
  );

  return (
    <ArticleSpeechSkipContainer>
      <Container>
        <ClientSideSuspense fallback={renderFallback()}>
          {queryReference ? (
            <PhotoGalleryWidget
              className={className}
              photoGalleryQueryReference={queryReference}
              reference={reference}
            />
          ) : (
            renderFallback()
          )}
        </ClientSideSuspense>
      </Container>
    </ArticleSpeechSkipContainer>
  );
};

export const PhotoGallery: RenderComponent<Props> = styled(Component)``;
PhotoGallery.isHandlingOwnChildren = true;
