import type { StyledComponent } from "@emotion/styled";
import styled from "@emotion/styled";
import type { FunctionComponent, HTMLAttributes } from "react";

import { normalizeJsxAttribute } from "shared/lib/utils";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

import { Heading1, Heading2, Heading3, Heading4 } from "./styles";

export type Props = ContentSchemaRenderProps & {
  isStaticPage?: boolean;
};

const Component: FunctionComponent<Props> = ({
  children,
  isStaticPage,
  schemaNode,
  ...attribs
}) => {
  const defaultRenderMap: {
    [key: string]: StyledComponent<HTMLAttributes<Element> & { type: string }>;
  } = {
    h1: Heading1,
    h2: isStaticPage ? Heading2 : Heading3,
    h3: Heading3,
    h4: Heading4,
    h5: Heading4,
    h6: Heading4,
  };

  if (defaultRenderMap[schemaNode.type]) {
    const Component = defaultRenderMap[schemaNode.type];
    return (
      <Component type={schemaNode.type} {...normalizeJsxAttribute(schemaNode.attribs)} {...attribs}>
        {children}
      </Component>
    );
  }

  return null;
};

Component.displayName = "Heading";
export const Heading = styled(Component)``;
