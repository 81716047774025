import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { GalleryIndicator } from "scmp-app/components/gallery/indicator";

export const ImageGrid = styled.div`
  position: relative;

  display: grid;
  grid:
    "main main main" min-content
    / 1fr 1fr 1fr;
  gap: 8px;

  inline-size: 100%;
`;

export const MainCell = styled.div`
  grid-area: main;

  inline-size: 100%;
  block-size: auto;

  aspect-ratio: 3 / 2;
`;

export const BottomCell = styled.div`
  grid-row-start: main-end;

  inline-size: 100%;
  block-size: auto;

  aspect-ratio: 3 / 2;
`;

type ImageProps = {
  $artDirection: "landscape" | "portrait";
};

export const Image = styled(BaseImage, { ...transientOptions })<ImageProps>`
  inline-size: 100%;
  block-size: 100%;

  cursor: pointer;

  object-fit: cover;
  object-position: ${props => (props.$artDirection === "portrait" ? "top" : "center")};
`;

export const StyledGalleryIndicator = styled(GalleryIndicator)`
  grid-area: main;
`;
