/**
 * @generated SignedSource<<c2fd4e437675099c94e0cf717fcc969e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type photoGalleryQuery$variables = {
  entityId: string;
};
export type photoGalleryQuery$data = {
  readonly content: {
    readonly " $fragmentSpreads": FragmentRefs<"photoGalleryWidgetGallery">;
  } | null | undefined;
};
export type photoGalleryQuery = {
  response: photoGalleryQuery$data;
  variables: photoGalleryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "contentType",
    "value": "GALLERY"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entityId",
        "variableName": "entityId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "photoGalleryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "photoGalleryWidgetGallery"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "photoGalleryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "content",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "images",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": "resized",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "style": "portrait_mq"
                        }
                      }
                    ],
                    "concreteType": "ImageStyle",
                    "kind": "LinkedField",
                    "name": "style",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": "style(filter:{\"style\":\"portrait_mq\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Gallery",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba8c2e2b894f4988bbc7c51e35fdd278",
    "id": null,
    "metadata": {},
    "name": "photoGalleryQuery",
    "operationKind": "query",
    "text": "query photoGalleryQuery(\n  $entityId: String!\n) {\n  content(contentType: GALLERY, filter: {entityId: $entityId}) {\n    __typename\n    ...photoGalleryWidgetGallery\n    id\n  }\n}\n\nfragment indicatorGalleryIndicatorGallery on Gallery {\n  images {\n    __typename\n  }\n}\n\nfragment photoGalleryDialogImage on Image {\n  title\n  url\n  resized: style(filter: {style: \"portrait_mq\"}) {\n    url\n  }\n}\n\nfragment photoGalleryWidgetGallery on Gallery {\n  ...indicatorGalleryIndicatorGallery\n  images {\n    ...photoGalleryDialogImage\n    width\n    height\n    title\n    url\n    resized: style(filter: {style: \"portrait_mq\"}) {\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2bad3b8794030d6186b1de18a217b34";

export default node;
