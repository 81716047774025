import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "shared/lib/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
    padding-inline: 20px;
  }
`;

export const Placeholder = styled.div`
  inline-size: 100%;
  block-size: auto;

  background-image: url("https://assets.i-scmp.com/data/live-qna/video_placeholder_desktop.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  aspect-ratio: 16 / 9;

  ${theme.breakpoints.only("mobile")} {
    background-image: url("https://assets.i-scmp.com/data/live-qna/video_placeholder_mobile.png");
  }
`;

export const CaptionLine = styled.div`
  inline-size: 100%;
  block-size: 2.4rem;
`;
