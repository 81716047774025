import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import { GenericIframe } from "scmp-app/components/schema-render/common/iframe/generic-iframe";
import { isScmpYoutubeVideo } from "scmp-app/components/schema-render/common/iframe/helpers";
import {
  SCMPYoutube,
  type Props as SCMPYoutubeProps,
} from "scmp-app/components/schema-render/common/iframe/scmp-youtube";
import type { liveArticleArticle$data } from "scmp-app/queries/__generated__/liveArticleArticle.graphql";

import { CaptionLine, Container, Placeholder } from "./styles";

export type Props = SCMPYoutubeProps & {
  isShowInlinePaywall?: boolean;
  liveArticleReference?: liveArticleArticle$data;
};

const Component: FunctionComponent<Props> = ({ isShowInlinePaywall, ...props }) => {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (isShowInlinePaywall === false) setHasAccess(true);
  }, [isShowInlinePaywall]);

  if (!hasAccess) {
    return (
      <>
        <Container>
          <Placeholder />
          <CaptionLine />
        </Container>
      </>
    );
  }

  const { src, ...attribs } = props;
  const isScmpYoutube = isScmpYoutubeVideo(attribs);

  if (!isScmpYoutube) {
    return <GenericIframe src={src} {...attribs} />;
  }

  return <SCMPYoutube {...props} />;
};

Component.displayName = "LiveQnAYoutubeVideo";
export const LiveQnAYoutubeVideo = styled(Component)``;
