/**
 * @generated SignedSource<<1960e82777c89cb6fdd610ca76a312c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type photoGalleryWidgetGallery$data = {
  readonly images: ReadonlyArray<{
    readonly height: number | null | undefined;
    readonly resized: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly title: string | null | undefined;
    readonly url: string;
    readonly width: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"photoGalleryDialogImage">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"indicatorGalleryIndicatorGallery">;
  readonly " $fragmentType": "photoGalleryWidgetGallery";
};
export type photoGalleryWidgetGallery$key = {
  readonly " $data"?: photoGalleryWidgetGallery$data;
  readonly " $fragmentSpreads": FragmentRefs<"photoGalleryWidgetGallery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "alias": "resized",
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {
        "style": "portrait_mq"
      }
    }
  ],
  "concreteType": "ImageStyle",
  "kind": "LinkedField",
  "name": "style",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": "style(filter:{\"style\":\"portrait_mq\"})"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "photoGalleryWidgetGallery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "indicatorGalleryIndicatorGallery"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "photoGalleryDialogImage",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Gallery",
  "abstractKey": null
};
})();

(node as any).hash = "e5f4f141304835ac7b1878a21a5135a8";

export default node;
