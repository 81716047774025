/**
 * @generated SignedSource<<e392d5018d7f65a3b6166ef0e335a889>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type checkersCheckContentContainsStarlingImageContent$data = {
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "checkersCheckContentContainsStarlingImageContent";
};
export type checkersCheckContentContainsStarlingImageContent$key = {
  readonly " $data"?: checkersCheckContentContainsStarlingImageContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkersCheckContentContainsStarlingImageContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "checkersCheckContentContainsStarlingImageContent"
};

(node as any).hash = "232b30868e0186c7eb3a0321f4d71cea";

export default node;
