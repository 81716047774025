import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { indicatorGalleryIndicatorGallery$key } from "scmp-app/queries/__generated__/indicatorGalleryIndicatorGallery.graphql";

import { Indicator, IndicatorIcon, IndicatorValue } from "./styles";

type ComponentProps = {
  className?: string;
  onClick?: () => void;
  reference: indicatorGalleryIndicatorGallery$key;
  withValue?: boolean;
};

export const GalleryIndicator: FunctionComponent<ComponentProps> = ({
  className,
  onClick,
  reference,
  withValue = true,
}) => {
  const gallery = useFragment(
    graphql`
      fragment indicatorGalleryIndicatorGallery on Gallery {
        images {
          __typename
        }
      }
    `,
    reference,
  );

  const filteredImages = gallery?.images?.filter(notEmpty) ?? [];

  return (
    <Indicator className={className} onClick={onClick}>
      <IndicatorIcon />
      {withValue && <IndicatorValue>{filteredImages.length}</IndicatorValue>}
    </Indicator>
  );
};

GalleryIndicator.displayName = "GalleryIndicator";
