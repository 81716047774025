import type { StyledComponent } from "@emotion/styled";
import styled from "@emotion/styled";
import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";

import { VideoSourceUrl } from "scmp-app/components/schema-render/common/iframe/generic-iframe/enums";
import { LiveQnAYoutubeVideo } from "scmp-app/components/schema-render/common/iframe/live-qna-youtube-video";
import {
  SCMPYoutube,
  type ExtraProps as YoutubeExtraProps,
} from "scmp-app/components/schema-render/common/iframe/scmp-youtube";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

import { DataWrapperIframe } from "./data-wrapper";
import { GenericIframe } from "./generic-iframe";
import { isDataWrapper, isScmpYoutubeVideo } from "./helpers";

export type Props = ContentSchemaRenderProps &
  YoutubeExtraProps & {
    checkAccessForYoutubeVideo?: boolean;
    "data-video-source"?: string;
    "data-youtube-id"?: string;
    isShowInlinePaywall?: boolean;
    src?: string;
  };

const Component: FunctionComponent<Props> = ({
  checkAccessForYoutubeVideo = false,
  src,
  ...attribs
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getIframeComponent = (): null | StyledComponent<any, any> => {
    // TODO: check if it's quiz
    if (!notEmpty(src)) return null;

    // when checkAccessForYoutubeVideo enabled
    // will use placeholder for server side first then replace with youtube component at client side
    if (isScmpYoutubeVideo(attribs))
      return checkAccessForYoutubeVideo ? LiveQnAYoutubeVideo : SCMPYoutube;

    // also apply placeholder for normal youtube video
    if (src.includes(VideoSourceUrl.Youtube))
      return checkAccessForYoutubeVideo ? LiveQnAYoutubeVideo : GenericIframe;

    if (isDataWrapper(src)) return DataWrapperIframe;
    return GenericIframe;
  };

  const Component = getIframeComponent();
  return Component ? <Component {...{ ...attribs, src }} /> : null;
};

Component.displayName = "Iframe";
export const Iframe = styled(Component)``;
