import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import type { StyledOptions } from "@emotion/styled";
import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

// eslint-disable-next-line unicorn/prevent-abbreviations
export const shouldForwardProp: StyledOptions["shouldForwardProp"] = property =>
  isPropValid(property) && property !== "content";

export const StyledCaption = styled("div", { shouldForwardProp })``;

export const StyledStrong = styled("strong", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledI = styled("i", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledEm = styled("em", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledUnderline = styled("u", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledOl = styled("ol", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledUl = styled("ul", {
  shouldForwardProp,
})`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledLi = styled("li", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledS = styled("s", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledSpan = styled("span", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
`;

export const StyledSup = styled("sup", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: smaller;
  font-family: inherit;
  line-height: inherit;
  vertical-align: super;
`;

export const StyledSub = styled("sub", { shouldForwardProp })`
  color: inherit;
  font-weight: inherit;
  font-size: smaller;
  font-family: inherit;
  line-height: inherit;
  vertical-align: sub;
`;

export const StyledTable = styled("table", { shouldForwardProp })`
  inline-size: 100%;
`;

export const StyledThead = styled("thead", { shouldForwardProp })`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 24px;

  background-color: #537ca633;
  ${theme.breakpoints.up("tablet")} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const tdThStyle = css`
  min-inline-size: 109px;
  padding-block: 12px;
  padding-inline: 0;

  font-size: 14px;
  text-align: center;

  border: 1px solid #ffffff;
  border-width: 0 2px;
  &:first-child {
    border-inline-start-width: 0;
  }
  &:last-child {
    border-inline-end-width: 0;
  }
`;

export const StyledTd = styled("td", { shouldForwardProp })`
  ${tdThStyle}
  ${theme.breakpoints.up("tablet")} {
    font-size: 16px;
  }
`;

export const StyledTh = styled("th", { shouldForwardProp })`
  min-inline-size: 109px;
  ${tdThStyle}
`;

export const StyledTr = styled("tr", { shouldForwardProp })`
  &:nth-child(2n) {
    background-color: #f6f6f6;
  }
`;

export const StyledTbody = styled("tbody", { shouldForwardProp })`
  inline-size: 100%;

  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 24px;

  ${theme.breakpoints.up("tablet")} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledPre = styled("pre", { shouldForwardProp })`
  display: inline-block;

  color: #6f6f6f;
  font-size: 13px;
  font-family: ${fontRoboto};
  line-height: 13px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
