import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

import {
  Caption,
  StyledContainer,
  StyledSummary,
  StyledTable,
  TableContainer,
  TableFooter,
  TableFooterBrand,
} from "./styles";
import { getTableCaptionsAndFilteredChildren } from "./utils";

export type Props = ContentSchemaRenderProps & {
  className?: string;
  summary?: string;
};

const Component: FunctionComponent<Props> = ({ children, className, ...attribs }) => {
  const [captions, filteredChildren] = getTableCaptionsAndFilteredChildren(children);

  const summary = attribs?.summary;
  return (
    <StyledContainer className={className}>
      {summary ? <StyledSummary>{summary}</StyledSummary> : null}
      {captions ? <Caption>{captions}</Caption> : null}
      <TableContainer>
        <StyledTable>{filteredChildren}</StyledTable>
      </TableContainer>
      <TableFooter>
        <TableFooterBrand>SCMP</TableFooterBrand>
      </TableFooter>
    </StyledContainer>
  );
};

Component.displayName = "Table";
export const Table = styled(Component)``;
