import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "shared/lib/styles";

import { SCMPYoutubeVideo } from "scmp-app/components/scmp-youtube-video";

export const StyledSCMPYoutubeVideo = styled(SCMPYoutubeVideo)`
  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
  }
`;
